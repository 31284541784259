<template>
    <div>
        <CCard>
            <CCardHeader><font-awesome-icon icon="dice"/> Template Signatory Matrix
            </CCardHeader>

            <CCardBody>
                <CTabs :active-tab="my_tab" @update:activeTab="changeTab">
                    <br>  
                    <CTab>
                        <template slot="title">
                            Active Matrix/s <CBadge color="success">( {{ [...new Set(active_matrix.map((item) => item.name))].length }} )</CBadge>
                        </template>
                        <CDataTable
                            :items="active_matrix"
                            :fields="tsm_lists_field"
                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                            :items-per-page="10"
                            border
                            items-per-page-select
                            sorter
                            pagination
                            :loading="table_loading"
                        >
                            <template slot="no-items-view" v-if="table_loading">
                                <center>
                                    <CSpinner
                                        style="width:4rem;height:4rem;"
                                        color="info"
                                    />
                                </center>
                            </template>
                            <template #name="{item}">
                                <td>
                                    {{ item.name }}
                                </td>
                            </template>
                            <template #summary="{item}">
                                <td>
                                    <CButton pressed
                                            aria-pressed="true"
                                            variant="ghost"
                                            class="btn-summary"
                                            title="Number of templates using this matrix."
                                            color="dark" 
                                            size="sm"
                                        >
                                            Used in {{ item.summary }} document template/s
                                    </CButton>
                                </td>
                            </template>
                            
                            <template #action="{item}">
                                <td>
                                    <!-- <router-link :to="{ path: `/${$store.getters['getOrganizationName']}/drs/document-template-shared/${item.id}`, query: {id:`${item.id}`,name: `${item.name}`, shared: item.dt_shared_count} }">
                                        <CButton color="info" shape="pill">
                                        <font-awesome-icon icon="cog"/> Manage
                                        </CButton>
                                    </router-link> -->
                                    <router-link :to="{ path: `/${$store.getters['getOrganizationName']}/drs/template-signatory-matrix/${paramEncoder(item.id)}`}">
                                        <CButton color="info" shape="pill" size="sm">
                                            <font-awesome-icon icon="cog"/> Manage
                                        </CButton>
                                    </router-link>
                                    
                                </td>
                            </template>
                        </CDataTable>
                    </CTab>
                    <CTab>
                        <template slot="title">
                            Inactive Matrix/s <CBadge color="success">( {{ [...new Set(inactive_matrix.map((item) => item.name))].length }} )</CBadge>
                        </template>
                        
                        <CDataTable
                            :items="inactive_matrix"
                            :fields="tsm_lists_field"
                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                            :items-per-page="10"
                            border
                            items-per-page-select
                            sorter
                            pagination
                            :loading="table_loading"
                        >
                            <template slot="no-items-view" v-if="table_loading">
                                <center>
                                    <CSpinner
                                        style="width:4rem;height:4rem;"
                                        color="info"
                                    />
                                </center>
                            </template>
                            <template #name="{item}">
                                <td>
                                    {{ item.name }}
                                </td>
                            </template>
                            <template #summary="{item}">
                                <td>
                                    <CButton pressed
                                            aria-pressed="true"
                                            variant="ghost"
                                            class="btn-summary"
                                            title="Number of templates using this matrix."
                                            color="dark" 
                                            size="sm"
                                        >
                                            Used in {{ item.summary }} document template/s
                                    </CButton>
                                </td>
                            </template>
                            
                            <template #action="{item, index}">
                                <td>
                                    <!-- <router-link :to="{ path: `/${$store.getters['getOrganizationName']}/drs/document-template-shared/${item.id}`, query: {id:`${item.id}`,name: `${item.name}`, shared: item.dt_shared_count} }">
                                        <CButton color="info" shape="pill">
                                        <font-awesome-icon icon="cog"/> Manage
                                        </CButton>
                                    </router-link> -->
                                    <CButton 
                                        color="warning" 
                                        shape="pill" 
                                        title="Restore Matrix"
                                        @click="restoreTemplateSignatoryMatrix(item, index)"
                                        :disabled="restoring_matrix && restoring_matrix_id == item.id" 
                                        size="sm">
                                        <font-awesome-icon :icon="restoring_matrix && restoring_matrix_id == item.id ? 'circle-notch' : 'trash-restore'" 
                                        :pulse="restoring_matrix && restoring_matrix_id == item.id"/>
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </CTab>
                </CTabs>


            </CCardBody>
        </CCard>
    </div>
</template>
<script>
export default {
    name: 'TemplateSignatoryMatrixLists',
	data() {
		return {
            tsm_lists: [
                // {id: 1, name: 'JO Routing Matrix', summary: 5,},
                // {id: 2, name: 'PO Routing Matrix', summary: 3,},
                // {id: 3, name: 'CRF Routing Matrix', summary: 2,},
                // {id: 4, name: 'Bank Certification Routing Matrix', summary: 4,},
            ],
            tsm_lists_field: [
                { key: 'name', label: 'Name'},
                { key: 'summary'},
                { key: 'action'},
            ],
            table_loading: true,
            
            my_tab: 0,

            restoring_matrix: false,

		}
	},
    computed: {

        active_matrix: function() {
            return this.tsm_lists.filter((item) => item.deleted_at == null);
        },

        inactive_matrix: function() {
            return this.tsm_lists.filter((item) => item.deleted_at != null);
        },
    },
    created() {
        this.$emit('activeTab', 0);
        this.getTemplateSignatoryMatrix();
    },  
	methods: {
        changeTab: function (item) {
            this.my_tab = item
        },      
        restoreTemplateSignatoryMatrix: function (item, index) {
            this.restoring_matrix = true;
            this.restoring_matrix_id = item.id
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to restore the <b>${item.name}</b>.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        id: this.paramEncoder(item.id)
                    }
                    return axios.post('drs/template-signatory-matrix/restore', data, {validateStatus: () => true})
                        .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${item.name} successfully restored.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            item.deleted_at = null
                            this.restoring_matrix = false;
                            this.restoring_matrix_id = null
                            this.$Progress.finish()
                        }
                        this.restoring_matrix = false;
                        this.restoring_matrix_id = null
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                    this.restoring_matrix = false;
                    this.restoring_matrix_id = null
                }
            })
        },
		getTemplateSignatoryMatrix: function () {
            this.$Progress.start()
            axios.get('drs/template-signatory-matrix/list', {validateStatus: ()=>true})
            .then( response => {
                this.tsm_lists = response.data.data;
                this.table_loading = false;
                this.$Progress.finish()
            });
        }
	}
}
</script>
